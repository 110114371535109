import React from "react";
import "./NoNotification.scss";
import NoNotif from "../../assets/no-order.png";

const NoNotification = () => {
  return (
    <div className="no-notification">
      <div className="no-notification-img">
        <img src={NoNotif} alt="No Notification" />
      </div>
      <h4>You have no notification.</h4>
      <p>Hold on tight! More updates incoming.</p>
    </div>
  );
};

export default NoNotification;
