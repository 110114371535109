import React from "react";
import { Outlet } from "react-router-dom";

import "./CartNDetails.scss";
import ProductCartDetailsTopBar from "../../navigator/ProductCartDetailsTopBar";
import Footer from "../../footer/Footer";

const CartNDetails = () => {
  return (
    <div>
      <ProductCartDetailsTopBar />
      <div className="container-fluid cardNdetail-outlet">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default CartNDetails;
