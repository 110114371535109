import React from "react";
import { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { SetAllContext } from "../../services/AllContext";
import { toast } from "react-toastify";
import "./profileSideBar.scss";

const ProfileSideBar = () => {
  const { setAuthToken, serUserID } = useContext(SetAllContext);
  // JS
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const handleSetActive = (link) => {
    setActiveLink(link);
  };
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  // =============== functions ===============
  function removeAuth() {
    setAuthToken(null);
    serUserID(null);
    localStorage.removeItem("authToken");
    localStorage.removeItem("userID");
    localStorage.removeItem("userName");
    toast.success(`Log out successful`, {
      autoClose: 3000, // 3 seconds
    });
  }
  return (
    <div className="sidebar">
      <div className="account">
        <Link
          className={`nav-link ${activeLink === "/profile" ? "active" : null} `}
          onClick={() => handleSetActive("/profile")}
          to="/profile"
        >
          <p>
            <i class="fa-solid fa-user"></i> Account
          </p>
        </Link>
      </div>
      <div className="order">
        <Link
          className={`nav-link ${
            activeLink === "/profile/orders" ? "active" : null
          } `}
          onClick={() => handleSetActive("/profile/orders")}
          to="/profile/orders"
        >
          <p>
            <i class="fa-solid fa-receipt"></i> My Orders
          </p>
        </Link>
      </div>
      <div className="notification">
        <Link
          className={`nav-link ${
            activeLink === "/profile/notifications" ? "active" : null
          } `}
          onClick={() => handleSetActive("/profile/notifications")}
          to="/profile/notifications"
        >
          <p>
            <i class="fa-solid fa-receipt"></i> Notification
          </p>
        </Link>
      </div>
      <div className="saved-items">
        {/* <Link
          className={`nav-link ${activeLink === "/#" ? "active" : null} `}
          onClick={() => handleSetActive("/#")}
          to="/#"
        > */}
        <p>
          <i class="fa-solid fa-receipt"></i> Saved Items
        </p>
        {/* </Link> */}
      </div>
      <div className="voucher">
        {/* <Link
          className={`nav-link ${activeLink === "/#" ? "active" : null} `}
          onClick={() => handleSetActive("/#")}
          to="/#"
        > */}
        <p>
          <i class="fa-solid fa-ticket-simple"></i> Voucher
        </p>
        {/* </Link> */}
      </div>
      <div className="delete-account">
        {/* <Link
          className={`nav-link ${activeLink === "/#" ? "active" : null} `}
          onClick={() => handleSetActive("/#")}
          to="/#"
        > */}
        <p>
          <i class="fa-solid fa-user-xmark"></i> Delete Account
        </p>
        {/* </Link> */}
      </div>
      <hr />
      <div className="logout">
        <Link
          className={`nav-link ${
            activeLink === "/auth/login" ? "active" : null
          } `}
          onClick={() => handleSetActive("/auth/login")}
          to="/auth/login"
        >
          <p style={{ marginBottom: "0" }} onClick={removeAuth}>
            LOG OUT
          </p>
        </Link>
      </div>
    </div>
  );
};

export default ProfileSideBar;
