import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import UserSurf from "./components/pages/surfPage";
import HomePage from "./components/home/HomePage";
import Vendors from "./components/vendors/Vendors";
import Shop from "./components/shop/Shop";
import FAQ from "./components/faq/faq";
import ContactUs from "./components/contact/contact";
import VendorProfile from "./components/vendors/pages/VendorsDetails";
import DetailsPage from "./components/pages/detailsPage";
import AccountProfile from "./components/profile-management/AccountProfile";
import ProfilePage from "./components/vendors/pages/ProfilePage";
import Order from "./components/profile-management/Order";
import Notification from "./components/profile-management/Notification";
import Login from "./components/auth/login";
import SignUp from "./components/auth/signup";
import CartNDetails from "./components/vendors/pages/CartNDetails";
import CartLists from "./components/cart/Cart";
import ProductDetailPage from "./components/shop/ProductDetailPage";
import OrderItem from "./components/profile-management/OrderItem";
// import Image2 from "./assets/jumbotron.png";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        {/* First Pages */}
        <Route path="/" element={<UserSurf />}>
          <Route path="" element={<Shop />} />
          <Route path="home" element={<HomePage />} />
          <Route path="vendors" element={<Vendors />} />
          <Route path="shop" element={<Shop />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="contact" element={<ContactUs />} />
        </Route>
        {/* Product Details */}
        <Route path="/detail" element={<DetailsPage />}>
          <Route path="" element={<VendorProfile />} />
          <Route path="product-details" element={<ProductDetailPage />} />
        </Route>
        {/* User's Profilings */}
        <Route path="/profile" element={<ProfilePage />}>
          <Route path="" element={<AccountProfile />} />
          <Route path="orders" element={<Order />} />
          <Route path="notifications" element={<Notification />} />
          <Route path="order-items" element={<OrderItem />} />
        </Route>
        {/* Authentication */}
        <Route path="/auth/login" element={<Login />} />
        {/* SignUp */}
        <Route path="/auth/register" element={<SignUp />} />
        {/* Product Details and Carting */}
        <Route path="/cart" element={<CartNDetails />}>
          <Route path="" element={<CartLists />} />
          {/* <Route path="" element={< />} />
          <Route path="notifications" element={< />} /> */}
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
