import React from "react";
import MainHeader from "../reusables/main_header";
import SmallerCard from "../reusables/smaller_card";
import Jumbotron from "../reusables/jumbotron";
// import { Link } from "react-router-dom";
import Image from "../../assets/homepageJumbotrone.png";
import Image2 from "../../assets/jumbotron.png";
// import NewArrivals from "../reusables/new_arrivals_wit_link";
// import Categorizing from "../reusables/categorizing";
// import Product from "../reusables/products";
// import Carder from "../reusables/card";
import ImgJumbotron from "../reusables/image_jumbotron";
import Supermarket from "../../assets/supermarket.jpeg";

import "./Homepage.css";

const HomePage = () => {
  return (
    <div>
      <div className="homepage-header">
        <div className="container">
          <MainHeader
            header={"Bringing You the Best, One Category at a Time!"}
            text={`Thank you for choosing Banke Store, 
              where new beginnings meet endless possibilities! 
              We're a young and enthusiastic team committed to 
              bringing you an exciting variety of products 
              that cater to all your needs. 
              Come explore and enjoy a world of choice!`}
            btn={"Shop Now"}
            image={Image}
            imgAlt={"Groceries Promotional image"}
          />
          <div className="row small-cards">
            <div className="col-md-3">
              <SmallerCard
                favicon={
                  <i
                    className="fa-solid fa-money-bill-wave"
                    style={{ color: "#1E90FF" }}
                  ></i>
                }
                header={"Buy Cheap"}
                text={"Tired of the Inflation?"}
              />
            </div>
            <div className="col-md-3">
              <SmallerCard
                favicon={
                  <i
                    className="fa-solid fa-cart-shopping"
                    style={{ color: "#1E90FF" }}
                  ></i>
                }
                header={"Explore Ease delivery"}
                text={"100% Guarantee"}
              />
            </div>
            <div className="col-md-3">
              <SmallerCard
                favicon={
                  <i
                    className="fa-regular fa-user"
                    style={{ color: "#1E90FF" }}
                  ></i>
                }
                header={"Safe & Secure"}
                text={"Your privacy is secure"}
              />
            </div>
            <div className="col-md-3">
              <SmallerCard
                favicon={
                  <i
                    className="fa-solid fa-arrow-rotate-right"
                    style={{ color: "#1E90FF" }}
                  ></i>
                }
                header={"New Products"}
                text={"New additions daily"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="homepage-jumbotrone-1">
        <Jumbotron
          header={"A GROWING MARKETPLACE FOR ALL YOUR NEEDS!"}
          text={`At Banke Store, we're proud to be a growing, 
            dynamic platform dedicated to providing you with a 
            carefully curated selection of products from different categories. 
            Whether you're looking for the latest trends or everyday essentials, 
            we're here to make your shopping experience seamless and enjoyable.
            We are also open to partnership with various vendors.`}
          button={"LEARN MORE"}
          jumbotronImage={Image2}
          imgAlt={"Jumbotron Image"}
        />
      </div>
      {/* ======================================================================= */}
      {/* <div className="homepage-new-product">
        <div className="container">
          <div className="row ">
            <div className="col-md-6">
              <NewArrivals
                message={"NEW ARRIVAL"}
                header={"Mars Ice Cream Tub 500ml"}
                image={Image2}
                imgAlt={"Image alternative"}
                links={"SHOP NOW"}
              />
            </div>
            <div className="col-md-6">
              <NewArrivals
                message={"NEW ARRIVAL"}
                header={"Mars Ice Cream Tub 500ml"}
                image={Image2}
                imgAlt={"Image alternative"}
                links={"SHOP NOW"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="homepage-delivery-commitment">
        <div className="container">
          <div className="row">
            <h5 style={{ color: "white", textAlign: "center" }}>
              GROCERY DELIVERY YOU CAN COUNT ON
            </h5>
            <p style={{ textAlign: "center", color: "#FFFFFF" }}>
              Shop Smart, Live Better with Banke
            </p>
            <div className="col-md-4">
              <Carder
                image={Image2}
                imgAlt={"Alternative1"}
                title={"Choose what you want"}
                text={
                  "Shop items from your preferred grocery stores on Banke.com or via the our mobile app."
                }
              />
            </div>
            <div className="col-md-4">
              <Carder
                image={Image2}
                imgAlt={"Alternative1"}
                title={"Choose what you want"}
                text={
                  "Shop items from your preferred grocery stores on Banke.com or via the our mobile app."
                }
              />
            </div>
            <div className="col-md-4">
              <Carder
                image={Image2}
                imgAlt={"Alternative1"}
                title={"Choose what you want"}
                text={
                  "Shop items from your preferred grocery stores on Banke.com or via the our mobile app."
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="homepage-categories">
        <div className="container">
          <div className="row">
            <h5 style={{ marginBottom: "32px", fontWeight: "600" }}>
              Shop by Category
            </h5>
            <div className="col-md-2">
              <Categorizing
                image={Image2}
                imgAlt={"..."}
                text={"Fresh Produce"}
              />
            </div>
            <div className="col-md-2">
              <Categorizing
                image={Image2}
                imgAlt={"..."}
                text={"Fresh Produce"}
              />
            </div>
            <div className="col-md-2">
              <Categorizing
                image={Image2}
                imgAlt={"..."}
                text={"Fresh Produce"}
              />
            </div>
            <div className="col-md-2">
              <Categorizing
                image={Image2}
                imgAlt={"..."}
                text={"Fresh Produce"}
              />
            </div>
            <div className="col-md-2">
              <Categorizing
                image={Image2}
                imgAlt={"..."}
                text={"Fresh Produce"}
              />
            </div>
            <div className="col-md-2">
              <Categorizing
                image={Image2}
                imgAlt={"..."}
                text={"Fresh Produce"}
              />
            </div>
            <div className="col-md-2">
              <Categorizing
                image={Image2}
                imgAlt={"..."}
                text={"Fresh Produce"}
              />
            </div>
            <div className="col-md-2">
              <Categorizing
                image={Image2}
                imgAlt={"..."}
                text={"Fresh Produce"}
              />
            </div>
            <div className="col-md-2">
              <Categorizing
                image={Image2}
                imgAlt={"..."}
                text={"Fresh Produce"}
              />
            </div>
            <div className="col-md-2">
              <Categorizing
                image={Image2}
                imgAlt={"..."}
                text={"Fresh Produce"}
              />
            </div>
            <div className="col-md-2">
              <Categorizing
                image={Image2}
                imgAlt={"..."}
                text={"Fresh Produce"}
              />
            </div>
            <div className="col-md-2">
              <Categorizing
                image={Image2}
                imgAlt={"..."}
                text={"Fresh Produce"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="homepage-new-products">
        <div className="container">
          <div className="row">
            <h5 style={{ marginBottom: "32px", fontWeight: "600" }}>
              New Products
            </h5>
            <div className="col-md-3">
              <Product
                image={Image}
                imageAlt={"..."}
                storeName={"Banke"}
                productName={"Garri"}
                productAmount={"16,000"}
                starRating={"*****"}
              />
            </div>
            <div className="col-md-3">
              <Product
                image={Image}
                imageAlt={"..."}
                storeName={"Banke"}
                productName={"Garri"}
                productAmount={"16,000"}
                starRating={"*****"}
              />
            </div>
            <div className="col-md-6">
              <NewArrivals
                message={"NEW ARRIVAL"}
                header={"Mars Ice Cream Tub 500ml"}
                image={Image2}
                imgAlt={"Image alternative"}
                links={"SHOP NOW"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="homepage-main-product">
        <div className="container">
          <div className="row">
            <h5 style={{ marginBottom: "32px", fontWeight: "600" }}>
              Beverages
            </h5>
            <div className="col-md-3">
              <Product
                image={Image2}
                imageAlt={"..."}
                storeName={"Banke"}
                productName={"Rice"}
                productAmount={"16,000"}
                starRating={"*****"}
              />
            </div>
            <div className="col-md-3">
              <Product
                image={Image}
                imageAlt={"..."}
                storeName={"Banke"}
                productName={"Garri"}
                productAmount={"16,000"}
                starRating={"*****"}
              />
            </div>
            <div className="col-md-3">
              <Product
                image={Image}
                imageAlt={"..."}
                storeName={"Banke"}
                productName={"Garri"}
                productAmount={"16,000"}
                starRating={"*****"}
              />
            </div>
            <div className="col-md-3">
              <Product
                image={Image}
                imageAlt={"..."}
                storeName={"Banke"}
                productName={"Garri"}
                productAmount={"16,000"}
                starRating={"*****"}
              />
            </div>
          </div>
        </div>
      </div> */}
      {/* ======================================================================== */}
      <div className="homepage-footer-jumbotron">
        <div className="container">
          <div
            className="row"
            style={{
              width: "75%",
              marginLeft: "auto",
              marginRight: "auto",
              height: "65vh",
            }}
          >
            <ImgJumbotron
              text1Style={{ fontSize: "22px", marginBottom: "0" }}
              text2Style={{ fontSize: "38px", marginBottom: "22px" }}
              text1={"Introducing"}
              text2={"Vendor partnership"}
              btnStyle={{ width: "270px" }}
              backgroundImage={Supermarket}
              btnText={"SHOW MORE"}
              navs={"/vendors"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
