import "./App.css";
import AppRouter from "./Router";
import { ContextProvider } from "./services/AllContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <ContextProvider>
        <AppRouter />
        <ToastContainer />
      </ContextProvider>
    </div>
  );
}

export default App;
