import React from "react";
import { Outlet } from "react-router-dom";
import "./ProfilePage.scss";
import ProfileSideBar from "../../navigator/profileSideBar";
import ProfileTopBar from "../../navigator/ProfileTopBar";
// import Notification from "../../profile-management/Notification";
// import Order from "../../profile-management/Order";
// import NoNotification from "../../profile-management/NoNotification";
// import AccountProfile from "../../profile-management/AccountProfile";
// import OrderNoTransaction from "../../profile-management/OrderNoTransaction";

const ProfilePage = () => {
  return (
    <div>
      <ProfileTopBar />
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <ProfileSideBar />
          </div>
          <div className="col-md-7">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
