import React from "react";
import "./contact.scss";

// import MainHeader from "../reusables/main_header";

// import Image from "../../assets/homepageJumbotrone.png";

const ContactUs = () => {
  return (
    <div>
      {/* <div className="contact-main-header">
        <div className="container">
          <MainHeader
            header={"Frequently Asked Questions"}
            text={`Experience the ease of shopping for fresh groceries online with Banke, where quality meets convenience. 
            From farm-fresh produce to pantry staples, enjoy swift delivery and hassle-free pickups that fit seamlessly into your busy schedule. 
            Discover a new way to shop that brings freshness to your doorstep with every order.`}
            image={Image}
            imgAlt={"Groceries Promotional image"}
          />
        </div>
      </div> */}
      <div className="contact-first-content">
        <div className="container">
          <p style={{ marginBottom: "40px", textAlign: "center" }}>
            <strong>"We’re Here for You!</strong> Whether you have questions,
            need assistance, or just want to say hello, our team at Banke Store
            is always ready to help. Your satisfaction is our priority, so don’t
            hesitate to reach out—we’re just a message away! 🏌🏼‍♂️
          </p>
          <div className="contact-first-content-highlight">
            <div className="highlight1 d-flex align-items-center">
              <div className="faviconz me-2">
                <i
                  class="fa-regular fa-envelope"
                  style={{ color: "#1E90FF" }}
                ></i>
              </div>
              <div>
                <h6 className="mb-0" style={{ color: "#1E90FF" }}>
                  For further Information and Inquiries
                </h6>
                <p className="mb-0">info@banke.org</p>
              </div>
            </div>
            <div className="highlight2 d-flex align-items-center">
              <div className="faviconz me-2">
                <i
                  class="fa-regular fa-envelope"
                  style={{ color: "#1E90FF" }}
                ></i>
              </div>
              <div>
                <h6 className="mb-0" style={{ color: "#1E90FF" }}>
                  For Media Requests
                </h6>
                <p className="mb-0">media@banke.org</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-form">
        <div className="container contact-form-container">
          <h4 style={{ textAlign: "center" }}>Contact Form</h4>
          <div className="mb-3">
            <label htmlFor={"contactName"} className="form-label">
              Enter Your Name
            </label>
            <input
              type="text"
              className="form-control"
              id={"contactName"}
              placeholder="Your Name"
            />
          </div>
          <div className="mb-3">
            <label htmlFor={"contactEmail"} className="form-label">
              Enter Your Email Address
            </label>
            <input
              type="email"
              className="form-control"
              id={"contactEmail"}
              placeholder="name@example.com"
            />
          </div>
          <div className="mb-3">
            <label htmlFor={"contactMessage"} className="form-label">
              Enter Your Message
            </label>
            <textarea
              className="form-control"
              id="contactMessage"
              placeholder="Your Message"
              rows="3"
            ></textarea>
          </div>
          <div className="mb-3 style-button">
            <button className="btn btn-primary col-12">SUBMIT</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
