import React from "react";
import { useState, createContext, useEffect } from "react";

export const SetAllContext = createContext();

export const ContextProvider = ({ children }) => {
  // ======= Hoping to get a better solution soon sha ====
  const initialAuthToken = localStorage.getItem("authToken") || null;
  const initialProductID = localStorage.getItem("productID") || null;
  const initialOrderId = localStorage.getItem("orderId") || null;
  const initialUserID = localStorage.getItem("userID") || null;
  const initialUserName = localStorage.getItem("userName") || null;
  // ======== All state management will go in here! ==========
  const [authToken, setAuthToken] = useState(initialAuthToken);
  const [productID, setProductID] = useState(initialProductID);
  const [orderId, setOrderId] = useState(initialOrderId);
  const [userID, serUserID] = useState(initialUserID);
  const [userName, setUserName] = useState(initialUserName);

  // ========= Add your state value here so it can be accessed by other components ==========
  const contextValues = {
    authToken,
    setAuthToken,
    productID,
    setProductID,
    orderId,
    setOrderId,
    userID,
    serUserID,
    userName,
    setUserName,
  };

  //   ========== UseEFFECT =============
  // Update localStorage whenever `authToken` changes
  useEffect(() => {
    if (authToken) {
      localStorage.setItem("authToken", authToken);
    } else {
      localStorage.removeItem("authToken"); // Remove if null
    }
  }, [authToken]);

  // Update localStorage whenever `productID` changes
  useEffect(() => {
    if (productID) {
      localStorage.setItem("productID", productID);
    } else {
      localStorage.removeItem("productID"); // Remove if null
    }
  }, [productID]);

  // Update localStorage whenever `orderID` changes
  useEffect(() => {
    if (orderId) {
      localStorage.setItem("orderId", orderId);
    } else {
      localStorage.removeItem("orderId"); // Remove if null
    }
  }, [orderId]);

  // Update localStorage whenever `userID` changes
  useEffect(() => {
    if (userID) {
      localStorage.setItem("userID", userID);
    } else {
      localStorage.removeItem("userID"); // Remove if null
    }
  }, [userID]);
  // Update localStorage whenever `userName` changes
  useEffect(() => {
    if (userName) {
      localStorage.setItem("userName", userName);
    } else {
      localStorage.removeItem("userName"); // Remove if null
    }
  }, [userName]);

  return (
    <SetAllContext.Provider value={contextValues}>
      {children}
    </SetAllContext.Provider>
  );
};
