import React from "react";
import BankeLogo from "../../assets/BankeMainLogo.png";
import { Link, useNavigate } from "react-router-dom";

import "./ProfileTopBar.scss";

const ProfileTopBar = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Go back to the previous URL
  };

  return (
    <div className="profile-top-bar bg-lights sticky-top">
      <div className="container profile-top-bar-container">
        <div className="profile-top-bar-Back" onClick={goBack}>
          <i class="fa-solid fa-chevron-left"></i> BACK
        </div>
        <div className="profile-top-bar-brand">
          <Link className="navbar-brand" to="/">
            <img src={BankeLogo} alt={"Banke Logo"} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProfileTopBar;
