import React from "react";
import "./faq.scss";
import { Link } from "react-router-dom";
import MainHeader from "../reusables/main_header";
import { useState } from "react";

import Image from "../../assets/faqTop.png";
import FAQImage from "../../assets/faqEndingImage.png";

const FAQ = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  // List of content items, each with a title and description
  const items = [
    {
      title: "What is Banke?",
      content: `Banke is a young and enthusiastic team committed to bringing you an 
        exciting variety of products that cater to all your needs. 
        Come explore and enjoy a world of choice!`,
    },
    {
      title: "How Banke works?",
      content: `Banke is focused at lifting the weight of the influation by 
        selling products at relatively cheap market price, 
        finding suitable vendors with quality, authentic yet low-priced products`,
    },
    {
      title: "Who are Vendors?",
      content: `Vendors are individuals or businesses 
      that sell products or services through the platform. 
      They list their items for sale and manage inventory`,
    },
    {
      title: "How to become a vendor?",
      content: `Have a viable business! Contact us! Manage your store!`,
    },
  ];

  // Function to toggle visibility of the selected div
  const handleToggle = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle between selected and null
  };

  return (
    <div>
      <div className="faq-main-header">
        <div className="container">
          <MainHeader
            header={"Got Questions? We’ve Got Answers!"}
            text={`Welcome to our FAQ section! We know you may have some questions, 
              and we're here to make things easy. 
              Whether you're curious about our products, shipping, or returns, 
              this is the place to find all the answers. 
              If you don’t see what you’re looking for, 
              feel free to reach out—we’re always happy to help`}
            image={Image}
            imgAlt={"Groceries Promotional image"}
          />
        </div>
      </div>
      <div className="faq-main-content">
        {items.map((item, index) => (
          <div key={index} style={{ marginBottom: "18px" }}>
            <div
              className="faq-main-content-header"
              onClick={() => handleToggle(index)}
            >
              <p style={{ fontWeight: "600" }}>{item.title}</p>
              {expandedIndex === index && (
                <p style={{ borderTop: "1px solid #242424" }}>
                  <em>{item.content}</em>
                </p>
              )}
            </div>

            {/* Conditionally render the content if it's expanded */}
          </div>
        ))}
        <img src={FAQImage} alt="..." className="image-fluid faq-image" />
        <div className="faq-sub-content">
          <hr
            style={{
              color: "#1E90FF",
              width: "20%",
              fontSize: "28px",
              marginLeft: "auto",
              marginRight: "auto",
              border: "2px solid #1E90FF",
              borderRadius: "12px",
            }}
          />
          <h5>Still have any questions?</h5>
          <p>
            If you cannot find answer to your question in our FAQ section, you
            can always contact us. We’ll answer you shortly.
          </p>
          <Link to="/contact">
            <button className="btn btn-primary col-md-5 btn-lg">
              CONTACT US
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
