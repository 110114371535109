import React from "react";
import { useState, useEffect, useContext } from "react";
import "./AccountProfile.scss";
import http from "../../services/http-commons.js";
import { SetAllContext } from "../../services/AllContext.js";

const AccountProfile = () => {
  const { userID } = useContext(SetAllContext);
  const [userData, setUserData] = useState(null);

  // ============== USEEFFECTS ================
  useEffect(() => {
    http.USERHTTP.get(`/users/${userID}`)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => {
        console.log("ERRORRRRR", error);
      });
  }, [userID]);

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="account-informataion">
      <div>
        <h4>Account Information</h4>
        <hr />
        <div className="mb-3">
          <label htmlFor={"firstName"} className="form-label">
            First Name
          </label>
          <input
            type="text"
            value={userData?.first_name || ""}
            disabled
            className="form-control form-control-lg"
            id={"firstName"}
          />
        </div>
        <div className="mb-3">
          <label htmlFor={"lastName"} className="form-label">
            Last Name
          </label>
          <input
            type="text"
            value={userData?.last_name || ""}
            disabled
            className="form-control form-control-lg"
            id={"lastName"}
          />
        </div>
        <div className="mb-3">
          <label htmlFor={"email"} className="form-label">
            Email Address
          </label>
          <input
            type="email"
            value={userData?.email || ""}
            disabled
            className="form-control form-control-lg"
            id={"email"}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountProfile;
