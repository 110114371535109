import React from "react";
import { useState, useEffect, useContext } from "react";
import http from "../../services/http-commons.js";
import OrderNoTransaction from "./OrderNoTransaction.js";
import { SetAllContext } from "../../services/AllContext.js";
// import Logo from "../../assets/BankeMainLogo.png";

import "./Order.scss";

const OrderItem = () => {
  const { orderId } = useContext(SetAllContext);
  // ==================== State =========================
  const [getOrderItems, setOrderItems] = useState(null);

  // =================== Effects =================
  useEffect(() => {
    http.ORDERITEMSHTTP.get(`/order_item/${orderId}`) //ORDER_ID Goes here also
      .then((response) => {
        setOrderItems(response.data);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  }, [orderId]);

  if (!getOrderItems) {
    return <div>Loading...</div>;
  }

  return (
    <div className="order-trans">
      <div className="order-trans-buttons">
        <button
          className="btn btn-primary col-12"
          style={{ marginRight: "3px" }}
        >
          Order Items{/* Ongoing */}
        </button>
      </div>
      {getOrderItems.length > 0 ? (
        getOrderItems.map((item, index) => (
          <div key={index} className="order-trans-content">
            <div className="order-trans-items">
              <div className="col-md-3 order-trans-items-img">
                <img
                  src={item?.product.images || ""}
                  alt={item?.product.name || ""}
                  style={{
                    width: "100px",
                    height: "100px",
                  }}
                />
              </div>
              <div className="col-md-7 order-trans-items-product">
                <h6>{item?.product.name || ""}</h6>
                <p>Vendor: Banke Grocery Store</p>
                <p>Quantity: {item?.quantity || ""}</p>
              </div>
              <div className="col-md-2 d-flex justify-content-center align-items-center">
                <p>
                  <strong>₦{item?.total_price || ""}</strong>
                </p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <OrderNoTransaction />
      )}
    </div>
  );
};

export default OrderItem;
