import React from "react";
import "./Notification.scss";
import NoNotification from "./NoNotification";
// import TestImage from "../../assets/faqEndingImage.png";

const Notification = () => {
  return (
    <div className="notification-profile">
      <NoNotification />
      {/* <div className="notification-profile-content">
        <div className="col-md-3 notification-profile-content-img">
          <img
            src={TestImage}
            alt="..."
            style={{
              width: "80px",
              height: "80px",
            }}
          />
        </div>
        <div className="col-md-7 notification-profile-content-product">
          <h6>Claim your Coupon!</h6>
          <p>
            You have been awarded with a 5% coupon for your first time purchase
          </p>
        </div>
        <div className="col-md-2 notification-profile-content-time">
          <p>2 mins ago</p>
        </div>
      </div> */}
    </div>
  );
};

export default Notification;
