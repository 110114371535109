import React from "react";
import { Link } from "react-router-dom";
import "./image_jumbotron.scss";

const ImgJumbotron = (props) => {
  return (
    <div
      className="imgJumbotron-main"
      style={{ backgroundImage: `url(${props.backgroundImage})` }}
    >
      <div className="content">
        <p className="paragraph1" style={{ ...props.text1Style }}>
          {props.text1}
        </p>
        <p className="paragraph2" style={{ ...props.text2Style }}>
          {props.text2}
        </p>
        <Link to={props.navs}>
          <button
            className="imgJumbotron-buttons btn btn-outline-primary"
            style={{ ...props.btnStyle }}
          >
            {props.btnText}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ImgJumbotron;

// backgroundImage
// text1
// text2
// btnText

/* You need to pass the stylings also */
// backgroundImage
// text1Style == {e.g => fontSize: "22px", marginBottom: "0"}
// text2Style == {e.g => fontSize: "38px", marginBottom: "22px"}
// btnStyle == {e.g => width: "270px"}

/* Passing it into the component will look like this */
/* 
    <ImgJumbotron
  text1="Introducing"
  text2="Black Thursday"
  btnText="SHOW MORE"
  text1Style={{ fontSize: "22px", marginBottom: "0" }} // Custom styles for text1
  text2Style={{ fontSize: "38px", marginBottom: "22px" }} // Custom styles for text2
  btnStyle={{ width: "270px" }} // Custom styles for button
/>
*/
