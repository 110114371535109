import React from "react";
import "./main_header.scss";
import { Link } from "react-router-dom";

const MainHeader = (props) => {
  return (
    <div>
      <div
        className="main-header row align-items-center"
        style={{ background: "#FFFFFF" }}
      >
        <div className="col-md-5 d-flex flex-column justify-content-center">
          <h2>{props.header}</h2>
          <p>{props.text}</p>
          {props.btn ? (
            <Link to="/shop">
              <button href={"/shop"} className="btn btn-outline-primary col-12">
                {props.btn}
              </button>
            </Link>
          ) : null}
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-6 d-flex justify-content-center">
          <img
            src={props.image}
            className="img-fluid img-style"
            alt={props.imgAlt}
          />
        </div>
      </div>
    </div>
  );
};

export default MainHeader;

// header
// text
// btn
// image
// imgAlt
