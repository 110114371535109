import React from "react";
import "./smaller_card.scss";

const SmallerCard = (props) => {
  return (
    <div className="small-card-main d-flex flex-md-column flex-lg-row align-items-center">
      <div className="faviconz me-2">{props.favicon}</div>
      <div className="small-card-main-text">
        <p className="mb-0">
          <strong>{props.header}</strong>
        </p>
        <p className="mb-0">{props.text}</p>
      </div>
    </div>
  );
};

export default SmallerCard;

// favicon {Please, pass along it the style for the icon}
// header {Text Header}
// text
