import React from "react";
import "./signup.scss";
import logo from "../../assets/BankeMainLogo.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import signupImage from "../../assets/signup.png";
import http from "../../services/http-commons.js";
import { toast } from "react-toastify";

const SignUp = () => {
  const navigate = useNavigate();

  const signUpData = {
    email: "",
    first_name: "",
    last_name: "",
    mobile_number: "",
    gender: "",
    age: 1,
    role: "customer",
    emailverified: false,
    verificationtoken: "1234567",
    created_at: new Date(),
    updated_at: new Date(),
    last_logged_in: new Date(),
    password: "",
    cpassword: "",
  };

  const [signupCred, setSignupCred] = useState(signUpData);

  function handleChange(e) {
    const { name, value } = e.target;
    setSignupCred({ ...signupCred, [name]: value });

    const updatedValue = name === "gender" ? value.toLowerCase() : value;

    setSignupCred({ ...signupCred, [name]: updatedValue });
  }
  function register() {
    const registeration = http.USERHTTP.post(`/register`, signupCred);
    toast.promise(
      registeration,
      {
        pending: "Signing up...",
        success: "Sign up successful! 🎉",
        error: "Unable to Sign up 😞",
      },
      {
        autoClose: 3000,
      }
    );
    registeration
      .then((response) => {
        if (response.status === 200) {
          navigate("/auth/login");
        }
        console.log(response);
      })
      .catch((error) => {
        toast.error(
          `${
            error.response.data.detail
              ? error.response.data.detail
              : "An error occured"
          }`,
          {
            autoClose: 3000, // 3 seconds
          }
        );
        console.log("Create-Update error", error.response.data.detail);
      });
  }

  return (
    <div className="signup-holder">
      <div className="signup-content">
        <div className="signup-content-headers">
          <h4>Create an Account</h4>
          <div className="signup-img">
            <Link to="/">
              <img src={logo} alt="..." width={"90px"} height={"45px"} />
            </Link>
          </div>
        </div>
        <div className="signup-content-form">
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor={"firstName"} className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  name="first_name"
                  value={signupCred.first_name}
                  onChange={handleChange}
                  className="form-control"
                  id={"firstName"}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor={"last_name"} className="form-label">
                  Surname
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="last_name"
                  value={signupCred.last_name}
                  onChange={handleChange}
                  id={"last_name"}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor={"contactEmail"} className="form-label">
                  Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  value={signupCred.email}
                  onChange={handleChange}
                  className="form-control"
                  id={"contactEmail"}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor={"mobile_number"} className="form-label">
                  Mobile
                </label>
                <input
                  type="number"
                  name="mobile_number"
                  value={signupCred.mobile_number}
                  onChange={handleChange}
                  className="form-control"
                  id={"mobile_number"}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor={"gender"} className="form-label">
                  Gender
                </label>
                <select
                  name="gender"
                  value={signupCred.gender}
                  onChange={handleChange}
                  className="form-control"
                  id="gender"
                >
                  <option disabled value="">
                    Select Gender
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor={"age"} className="form-label">
                  Age
                </label>
                <input
                  type="number"
                  name="age"
                  value={signupCred.age}
                  onChange={handleChange}
                  className="form-control"
                  id={"age"}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor={"password"} className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  value={signupCred.password}
                  onChange={handleChange}
                  className="form-control"
                  id={"password"}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor={"cpassword"} className="form-label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  name="cpassword"
                  value={signupCred.cpassword}
                  onChange={handleChange}
                  className="form-control"
                  id={"cpassword"}
                />
              </div>
            </div>
          </div>

          <p>By Signing up, you accept our Terms and Conditions</p>
          <div className="mb-3 style-button">
            <button
              className="btn btn-primary col-12"
              onClick={register}
              // data-bs-toggle="modal"
              // data-bs-target="#staticBackdrop"
            >
              Sign up
            </button>
          </div>
        </div>
        <p style={{ textAlign: "center" }}>
          Already have an account? <Link to="/auth/login">Login</Link>
        </p>
      </div>
      <div className="signup-image">
        <img src={signupImage} alt="..." />
      </div>

      {/* ---------MODALS------------- */}
      {/* Create PASSWORD */}
      {/* <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="container-fluid modal-content">
            <p
              data-bs-dismiss="modal"
              className="d-flex justify-content-end align-items-center"
            >
              <i style={{ cursor: "pointer" }} class="fa-solid fa-xmark"></i>
            </p>
            <div
              style={{ width: "20%", marginLeft: "auto", marginRight: "auto" }}
            >
              <img src={logo} alt="Banke" width={"70px"} height={"40px"} />
            </div>
            <div>
              <h4 style={{ fontSize: "18px" }}>Create new password</h4>
              <p style={{ fontSize: "14px", textAlign: "center" }}>
                Please enter a new password. Your new password must be different
                from previous password.
              </p>
            </div>
            <form>
              <div className="mb-3">
                <label htmlFor={"enterPassword"} className="form-label">
                  <strong style={{ color: "grey" }}>Enter Password</strong>
                </label>
                <input
                  type="password"
                  className="form-control"
                  id={"enterPassword"}
                  placeholder="********"
                />
              </div>
              <div className="mb-3">
                <label htmlFor={"confirmPassword"} className="form-label">
                  <strong style={{ color: "grey" }}>Confirm Password</strong>
                </label>
                <input
                  type="password"
                  className="form-control"
                  id={"confirmPassword"}
                  placeholder="********"
                />
              </div>
            </form>
            <div>
              <button type="button" className="btn btn-primary col-12">
                Continue
              </button>
            </div>
            <p
              data-bs-dismiss="modal"
              style={{ color: "#1E90FF", fontSize: "11px", cursor: "pointer" }}
            >
              Return to login
            </p>
          </div>
        </div>
      </div> */}
      {/* Confirm password CREATION */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="container-fluid modal-content">
            <p
              data-bs-dismiss="modal"
              className="d-flex justify-content-end align-items-center"
            >
              <i style={{ cursor: "pointer" }} class="fa-solid fa-xmark"></i>
            </p>
            <div
              style={{ width: "20%", marginLeft: "auto", marginRight: "auto" }}
            >
              <img src={logo} alt="Banke" width={"70px"} height={"40px"} />
            </div>
            <div>
              <h4 style={{ fontSize: "16px", textAlign: "center" }}>
                {"FirstName"}, your account has been created
              </h4>
            </div>
            <div>
              <button type="button" className="btn btn-primary col-12">
                Return back to login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
