import React from "react";
import "./jumbotron.scss";
import { Link } from "react-router-dom";

const Jumbotron = (props) => {
  return (
    <>
      <div className="container main-jumbotron">
        <div className="row jumbotron">
          {/* TEXTS */}
          <div className="col-md-6 jumbotron-texts">
            <h3>{props.header}</h3>
            <p>{props.text}</p>
            <span>
              <Link to="/shop">
                <button className="btn btn-primary col-12" type="button">
                  {props.button}
                </button>
              </Link>
            </span>
          </div>
          <div className="col-md-1"></div>
          {/* IMAGE */}
          <div className="col-md-5 jumbotron-image">
            <img
              src={props.jumbotronImage}
              className="img-fluid"
              style={{ height: "auto", width: "100%" }}
              alt={props.imgAlt}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Jumbotron;
