import React from "react";
import "./OrderNoTransaction.scss";
import NoCart from "../../assets/no-order.png";

const OrderNoTransaction = () => {
  return (
    <div className="order-no-transa">
      <div className="order-no-transa-img">
        <img src={NoCart} alt="No Cart" />
      </div>
      <h4>No Transaction History.</h4>
      <p>You have not made any purchase recently.</p>
    </div>
  );
};

export default OrderNoTransaction;
