import React from "react";
import { useState, useEffect, useContext } from "react";
import http from "../../services/http-commons.js";
import OrderNoTransaction from "./OrderNoTransaction.js";
import { SetAllContext } from "../../services/AllContext.js";
import Logo from "../../assets/BankeMainLogo.png";
import { useNavigate } from "react-router-dom";

import "./Order.scss";

const Order = () => {
  const navigate = useNavigate();
  const { userID, setOrderId } = useContext(SetAllContext);
  // ==================== State =========================
  const [getOrders, setGetOrders] = useState(null);

  // =================== Effects =================
  useEffect(() => {
    http.ORDERHTTP.get(`/orders/${userID}`) //User_ID Goes here also
      .then((response) => {
        setGetOrders(response.data);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  }, [userID]);

  //  ============== Functions ================
  function formatDate(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const timeOptions = { hour: "2-digit", minute: "2-digit" };

    return `${date.toLocaleDateString(
      undefined,
      options
    )} at ${date.toLocaleTimeString(undefined, timeOptions)}`;
  }

  function openOrderItem(orderId) {
    setOrderId(orderId);
    navigate("/profile/order-items");
  }

  if (!getOrders) {
    return <div>Loading...</div>;
  }

  return (
    <div className="order-trans">
      <div className="order-trans-buttons">
        <button
          className="btn btn-primary col-12"
          style={{ marginRight: "3px" }}
        >
          Orders{/* Ongoing */}
        </button>
        {/* <button className="btn btn-outline-primary col-6">Completed</button> */}
      </div>
      {getOrders.length > 0 ? (
        getOrders.map((item, index) => (
          <div key={index} className="order-trans-content">
            <div
              className="order-trans-items"
              onClick={() => openOrderItem(item.id)}
              style={{ cursor: "pointer" }}
            >
              <div className="col-md-3 order-trans-items-img">
                <img
                  src={Logo} //{item?.product.images || ""}
                  alt="logo" //{item?.product.name || ""}
                  style={{
                    width: "100px",
                    height: "100px",
                  }}
                />
              </div>
              <div className="col-md-7 order-trans-items-product">
                <h6>{item?.status || ""}</h6>
                <p>
                  <strong>Ordered date:</strong>{" "}
                  <em>{formatDate(item?.order_date)}</em>
                </p>
                <p>
                  <strong>
                    Mobile line: <em>{item?.deliveries.mobile_number}</em>
                  </strong>
                </p>
                {/* <p>Vendor: Banke Grocery Store</p> */}
                {/* <p>Quantity: {item?.quantity || ""}</p> */}
              </div>
              <div className="col-md-2 d-flex justify-content-center align-items-center">
                <p>
                  <strong>₦{item?.total_amount || ""}</strong>
                </p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <OrderNoTransaction />
      )}
    </div>
  );
};

export default Order;
