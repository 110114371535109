import React from "react";
import "./login.scss";
import logo from "../../assets/BankeMainLogo.png";
import loginImage from "../../assets/login.png";
import { useState, useContext } from "react";
import { SetAllContext } from "../../services/AllContext.js";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import http from "../../services/http-commons.js";

const Login = () => {
  const navigate = useNavigate();
  // ================ Contexts ================
  const { setAuthToken, serUserID, setUserName } = useContext(SetAllContext);
  // ================ useState =================
  const [loginCred, setLoginCred] = useState({ username: "", password: "" });

  // ============ Functions =============
  function handleChange(e) {
    const { name, value } = e.target;
    setLoginCred({ ...loginCred, [name]: value });
  }

  function logUserIn() {
    const loginPromise = http.USERHTTP.post(`/login`, loginCred);

    toast.promise(
      loginPromise,
      {
        pending: "Logging in...",
        success: "Login successful! 🎉",
        error: "Unable to log in 😞",
      },
      {
        autoClose: 3000,
      }
    );
    loginPromise
      .then((response) => {
        setAuthToken(response.data.access_token);
        serUserID(response.data.user_details.id);
        setUserName(response.data.user_details.firstname);
        navigate("/");
      })
      .catch((error) => {
        console.log("Create-Update error", error);
      });
  }

  return (
    <div className="login-holder">
      <div className="login-content">
        <div className="login-content-headers">
          <h4>Welcome to Banke</h4>
          <div className="login-img">
            <Link to="/">
              <img src={logo} alt="Banke" width={"90px"} height={"45px"} />
            </Link>
          </div>
        </div>
        <div className="login-content-form">
          <div className="mb-3">
            <label htmlFor={"contactEmail"} className="form-label">
              Enter Your Email Address
            </label>
            <input
              type="email"
              name="username"
              value={loginCred.username}
              onChange={handleChange}
              className="form-control"
              id={"contactEmail"}
              placeholder="name@example.com"
            />
          </div>
          <div className="mb-3">
            <label htmlFor={"password"} className="form-label">
              Enter Your Password
            </label>
            <input
              type="password"
              name="password"
              value={loginCred.password}
              onChange={handleChange}
              className="form-control"
              id={"password"}
              placeholder="********"
            />
          </div>
          <p>
            <a
              href="/#"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              Forgot password?
            </a>
          </p>
          <div className="mb-3 style-button">
            <button onClick={logUserIn} className="btn btn-primary col-12">
              Login
            </button>
          </div>
        </div>

        <p className="login-footer-text" style={{ textAlign: "center" }}>
          Don’t have an account? <Link to="/auth/register">Sign Up</Link>
        </p>
        <div>
          <img src={loginImage} className="login-image" alt={"..."} />
        </div>
      </div>
      {/* ------------------------------------ */}
      {/* Reset password */}
      {/* <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="container-fluid modal-content">
            <p
              data-bs-dismiss="modal"
              className="d-flex justify-content-end align-items-center"
            >
              <i style={{ cursor: "pointer" }} class="fa-solid fa-xmark"></i>
            </p>
            <div
              style={{ width: "20%", marginLeft: "auto", marginRight: "auto" }}
            >
              <img src={logo} alt="Banke" width={"70px"} height={"40px"} />
            </div>
            <div>
              <h4 style={{ fontSize: "18px" }}>Reset your password</h4>
              <p style={{ fontSize: "14px", textAlign: "center" }}>
                Enter the email address associated with your account and we’ll
                send you a link to reset your password.
              </p>
            </div>
            <form>
              <div className="mb-3">
                <label htmlFor={"contactEmail"} className="form-label">
                  <strong style={{ color: "grey" }}>
                    Enter Your Email Address
                  </strong>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id={"contactEmail"}
                  placeholder="name@example.com"
                />
              </div>
            </form>
            <div>
              <button type="button" className="btn btn-primary col-12">
                Continue
              </button>
            </div>
            <p
              data-bs-dismiss="modal"
              style={{ color: "#1E90FF", fontSize: "11px", cursor: "pointer" }}
            >
              Return to login
            </p>
          </div>
        </div>
      </div> */}
      {/* Confirm Reset password */}
      {/* <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="container-fluid modal-content">
            <p
              data-bs-dismiss="modal"
              className="d-flex justify-content-end align-items-center"
            >
              <i style={{ cursor: "pointer" }} class="fa-solid fa-xmark"></i>
            </p>
            <div
              style={{ width: "20%", marginLeft: "auto", marginRight: "auto" }}
            >
              <img src={logo} alt="Banke" width={"70px"} height={"40px"} />
            </div>
            <div>
              <h4 style={{ fontSize: "16px" }}>Reset your password</h4>
              <p style={{ fontSize: "12px", textAlign: "center" }}>
                An email has been sent to {"Winnereffiong@gmail.com."} You’ll
                receive instructions on how to set a new password.
              </p>
            </div>
            <div>
              <button type="button" className="btn btn-primary col-12">
                Reset Password
              </button>
            </div>
            <p
              data-bs-dismiss="modal"
              style={{ color: "#1E90FF", fontSize: "11px", cursor: "pointer" }}
            >
              Return to login
            </p>
          </div>
        </div>
      </div> */}
      {/* Create PASSWORD */}
      {/* <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="container-fluid modal-content">
            <p
              data-bs-dismiss="modal"
              className="d-flex justify-content-end align-items-center"
            >
              <i style={{ cursor: "pointer" }} class="fa-solid fa-xmark"></i>
            </p>
            <div
              style={{ width: "20%", marginLeft: "auto", marginRight: "auto" }}
            >
              <img src={logo} alt="Banke" width={"70px"} height={"40px"} />
            </div>
            <div>
              <h4 style={{ fontSize: "18px" }}>Create new password</h4>
              <p style={{ fontSize: "14px", textAlign: "center" }}>
                Please enter a new password. Your new password must be different
                from previous password.
              </p>
            </div>
            <form>
              <div className="mb-3">
                <label htmlFor={"enterPassword"} className="form-label">
                  <strong style={{ color: "grey" }}>Enter Password</strong>
                </label>
                <input
                  type="password"
                  className="form-control"
                  id={"enterPassword"}
                  placeholder="********"
                />
              </div>
              <div className="mb-3">
                <label htmlFor={"confirmPassword"} className="form-label">
                  <strong style={{ color: "grey" }}>Confirm Password</strong>
                </label>
                <input
                  type="password"
                  className="form-control"
                  id={"confirmPassword"}
                  placeholder="********"
                />
              </div>
            </form>
            <div>
              <button type="button" className="btn btn-primary col-12">
                Continue
              </button>
            </div>
            <p
              data-bs-dismiss="modal"
              style={{ color: "#1E90FF", fontSize: "11px", cursor: "pointer" }}
            >
              Return to login
            </p>
          </div>
        </div>
      </div> */}
      {/* Confirm password CREATION */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="container-fluid modal-content">
            <p
              data-bs-dismiss="modal"
              className="d-flex justify-content-end align-items-center"
            >
              <i style={{ cursor: "pointer" }} class="fa-solid fa-xmark"></i>
            </p>
            <div
              style={{ width: "20%", marginLeft: "auto", marginRight: "auto" }}
            >
              <img src={logo} alt="Banke" width={"70px"} height={"40px"} />
            </div>
            <div>
              <h4 style={{ fontSize: "16px", textAlign: "center" }}>
                You have successfully created your password
              </h4>
            </div>
            <div>
              <button type="button" className="btn btn-primary col-12">
                Return back to login
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------------------ */}
    </div>
  );
};

export default Login;
