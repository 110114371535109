import React, { useState, useEffect, useCallback, useContext } from "react";
import "./ProductDetailPage.scss";
// import TestImage from "../../assets/homepageJumbotrone.png";
import { toast } from "react-toastify";
import http from "../../services/http-commons.js";
import { SetAllContext } from "../../services/AllContext.js";

const ProductDetailPage = () => {
  // All Variables go here
  const { productID, userID } = useContext(SetAllContext);

  // All useSTATE goes here
  const [quantity, setQuantity] = useState(1);
  const [productData, setProductData] = useState(null);
  const [error, setError] = useState(null); // To track errors
  const [cartBody, setCartBody] = useState(null);

  // All useEFFECT goes here
  useEffect(() => {
    // const controller = new AbortController(); // to cancel the request if component unmounts
    http.PRODUCTHTTP.get(`/get-product/${productID}`)
      .then((response) => {
        console.log("Product Data:", response.data);
        setProductData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError(
            `Error: ${
              error.response.data.message || "Unable to fetch product."
            }`
          );
        } else if (error.request) {
          setError("No response from server. Please try again later.");
        } else {
          setError("An error occurred.");
        }
      });
  }, [productID]);
  useEffect(() => {
    if (cartBody) {
      const addCart = http.CARTHTTP.post("/add-cart", cartBody);
      toast.promise(
        addCart,
        {
          pending: "Adding to cart...",
          success: "Product successfully added to cart 🎉",
          error: "Error adding product to cart 😞",
        },
        {
          autoClose: 3000, // auto close after 3 seconds for each notification
        }
      );
      addCart
        .then((response) => {
          console.log("response", response);
        })
        .catch((error) => {
          console.log("ERROR", error);
          if (
            error.response &&
            error.response.data.detail === "Could not validate credentials"
          )
            toast.error(`Please, log in 🤤`, {
              autoClose: 3000,
            });
        });
    }
  }, [cartBody]);

  // All FUNCTIONS go here
  function decrsQuantity() {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  }

  function incrsQuantity() {
    setQuantity((prevQuantity) => prevQuantity + 1);
  }

  const addToCart = useCallback(() => {
    if (!productData) return;
    setCartBody({
      user_id: userID,
      product_id: productData.id,
      quantity: quantity,
      price_per_item: productData.amount,
    });
  }, [productData, quantity, userID]);

  // ============= ALL HTML GOES HERE ===============
  const vendorName = "Banke Grocery Store";
  const stars = Array(productData?.ratings || 0).fill(
    <i
      className="fa-solid fa-star"
      aria-hidden="true"
      style={{ color: "#1e90ff" }}
    ></i>
  );
  if (error) {
    return <div>Error: {error}</div>; // Display error message
  }

  if (!productData) {
    return <div>Loading...</div>; // Show loading state while fetching data
  }

  return (
    <>
      {/* Product main/Header */}
      <div className="ProductDetailPage-product">
        <div className="container ProductDetailPage-jumbotrone">
          <div className="row ProductDetailPage-jumbotrone-row">
            {/* Product Image */}
            <div className="col-md-4">
              <h5>Product Details Page</h5>
              <img
                src={productData.images}
                height={"80%"}
                width={"80%"}
                loading="lazy"
                alt={productData.name || "Product image"}
              />
            </div>
            {/* Product Info */}
            <div className="col-md-4 ProductDetailPage-jumbotrone-info">
              <p>
                <i className="fa-solid fa-store"></i> {vendorName}
              </p>
              <h5>{productData.name}</h5>
              <p>
                <strong>#{productData.amount}</strong>
              </p>
              <p>
                {stars.map((icon, index) => (
                  <span key={index} style={{ margin: "0 5px" }}>
                    {icon}
                  </span>
                ))}
              </p>
              <p>
                Quantity:{" "}
                <span
                  style={{
                    border: "1px solid black",
                    padding: "6px",
                    marginLeft: "6px",
                    cursor: "pointer",
                  }}
                  onClick={decrsQuantity}
                >
                  -
                </span>
                <span style={{ border: "1px solid black", padding: "6px 8px" }}>
                  {quantity}
                </span>
                <span
                  style={{
                    border: "1px solid black",
                    padding: "6px",
                    cursor: "pointer",
                  }}
                  onClick={incrsQuantity}
                >
                  +
                </span>
              </p>
              <button
                onClick={addToCart}
                type="button"
                className="btn btn-primary col-6"
                style={{ marginBottom: "12px" }}
              >
                Buy
              </button>
            </div>
            {/* Vendor Info */}
            <div className="col-md-4">
              <p style={{ border: "1px solid #CCCCCC", padding: "6px 12px" }}>
                Vendor Information
              </p>
              <p style={{ color: "#1e90ff" }}>
                <strong>{vendorName}</strong>
              </p>
              {/* Vendor details */}
              <p style={{ textAlign: "justify", color: "grey" }}>
                Welcome to Banke Store, we're proud to be a growing, dynamic
                platform dedicated to providing you with a carefully curated
                selection of products from different categories.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Product Description */}
      <div className="container ProductDetailPage-description">
        <p className="ProductDetailPage-description-p">Product Description</p>{" "}
        <hr />
        <p style={{ color: "#7B7B7B" }}>{productData.description}</p>
      </div>
    </>
  );
};

export default ProductDetailPage;
