import React from "react";
import "./Vendors.scss";

// import Image2 from "../../assets/BankeMainLogo.png";
import Image from "../../assets/vendors_notifications.png";
import Image3 from "../../assets/homepageJumbotrone.png";
import supermarket from "../../assets/supermarket.jpeg";
import MainHeader from "../reusables/main_header";
// import VendorCard from "../reusables/vendor_card";
import ImgJumbotron from "../reusables/image_jumbotron";

const Vendors = () => {
  const getResponsiveStyles = () => {
    const width = window.innerWidth;

    if (width <= 576) {
      return {
        text1Style: { fontSize: "17px", marginBottom: "0" },
        text2Style: { fontSize: "10px", marginBottom: "22px" },
        btnStyle: { width: "250px" },
      };
    } else if (width > 768) {
      return {
        text1Style: { fontSize: "28px", marginBottom: "0" },
        text2Style: { fontSize: "14px", marginBottom: "22px" },
        btnStyle: { width: "300px" },
      };
    } else {
      return {
        text1Style: { fontSize: "28px", marginBottom: "0" },
        text2Style: { fontSize: "14px", marginBottom: "22px" },
        btnStyle: { width: "300px" },
      };
    }
  };
  const responsiveStyles = getResponsiveStyles();
  return (
    <div>
      <div className="vendor-main-header">
        <div className="container">
          <MainHeader
            header={"Discover Our Vibrant Vendor Community"}
            text={`Welcome to the heart of our e-commerce platform — 
              a place where you can meet the diverse and passionate vendors 
              who bring their unique products right to your fingertips. 
              This page is your gateway to discovering an eclectic range of small businesses, 
              local artisans, and innovative brands, 
              each with a story to tell and something special to offer.`}
            image={Image3}
            imgAlt={"Vendors page"}
          />
        </div>
      </div>
      <div className="main-content">
        <div className="container container-content">
          <div className="row">
            {/* <div className="input-group inputHolder">
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Search Product"
              />
              <span className="btn btn-primary btn-lg" type="button">
                Search
              </span>
            </div> */}
          </div>
          {/* <h4 style={{ marginTop: "32px", marginBottom: "18px" }}>Vendors</h4> */}
          <div className="row container-content-vendors">
            <h4 style={{ textAlign: "center" }}>
              Vendor discovery page coming soon! We're building something
              amazing—stay tuned for updates!
            </h4>
            <img
              src={Image}
              alt="vendors_notifications"
              style={{
                height: "60vh",
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            {/* <div className="col-md-3">
              <VendorCard
                image={Image2}
                imgAlt={"Alternative1"}
                title={"Banke Grocery Store"}
              />
            </div>
            <div className="col-md-3">
              <VendorCard
                image={Image2}
                imgAlt={"Alternative1"}
                title={"Banke Grocery Store"}
              />
            </div>
            <div className="col-md-3">
              <VendorCard
                image={Image2}
                imgAlt={"Alternative1"}
                title={"Banke Grocery Store"}
              />
            </div>
            <div className="col-md-3">
              <VendorCard
                image={Image2}
                imgAlt={"Alternative1"}
                title={"Banke Grocery Store"}
              />
            </div> */}
          </div>
        </div>
      </div>
      <div className="jumbotron-area">
        <div
          className="row"
          style={{
            width: "75%",
            marginLeft: "auto",
            marginRight: "auto",
            height: "45vh",
          }}
        >
          <ImgJumbotron
            text1Style={responsiveStyles.text1Style}
            text2Style={responsiveStyles.text2Style}
            text1={"Can’t Spot Your Fave Vendor or Product?"}
            text2={"Hit us up with your recs, and we’ll add it to the lineup!"}
            btnStyle={responsiveStyles.btnStyle}
            backgroundImage={supermarket}
            btnText={"SHOOT YOUR REQUEST"}
            navs={"/contact"}
          />
        </div>
      </div>
    </div>
  );
};

export default Vendors;
